import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js';
import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js'

// Connects to data-controller="emr"
export default class extends NestedForm {
    static targets = [
        "editOrder",
        "detailStatusSubmitButton",
        "detailStatusForm",
        "editOrderLoadingSpinner",
        "vendorMap",
        "emrForm",
        "emrFormWrapper",
        "total",
        "price",
        "quantity",
        "submitButton",
        "loading",
        "vendorLoading"
    ]

    connect() {
        const selectElements = document.querySelectorAll('.js-choice-select');
        selectElements?.forEach(selectElement => {
            // create a new choices instance
            const choices = new Choices(selectElement, {
                allowHTML: false,
                searchPlaceholderValue: 'Enter Vendor Name',
                searchResultLimit: 10,
                itemSelectText: 'click to select',
                classNames: {
                    containerInner: 'form-input',
                }
            });
        });
        const config = { attributes: true, childList: true, subtree: true };
        const formObserver = new MutationObserver(this.handleFormMutation.bind(this));
        formObserver.observe(this.emrFormWrapperTarget, config);
        this.displayMap();
    }

    handleFormMutation(mutationsList) {
        if (!mutationsList.some(mutation => mutation.target === this.totalTarget)) {
            this.updateTotal();
        }
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                mutation.removedNodes?.forEach(node => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        const selects = node.querySelectorAll('select');
                        const inputs = node.querySelectorAll('input');
                        const textAreas = node.querySelectorAll('textarea');
                        selects.forEach(select => {
                            select.removeAttribute('required');
                        });
                        inputs.forEach(input => {
                            input.removeAttribute('required');
                            input.removeAttribute('min');
                        });
                        textAreas.forEach(textArea => {
                            textArea.removeAttribute('required');
                        });
                    }
                });
            }
        }
    }

  editOrder() {
      this.editOrderTarget.classList.remove('hidden')
  }

    changeDetailStatus(event) {
        // Need to show the submit button for this specific form
        this.detailStatusSubmitButtonTargets.forEach((button) => {
            const closestForm = button.closest('form');
            if (closestForm.dataset.detailId === event.target.dataset.detailId) {
                button.classList.remove('hidden');
            } else {
                button.classList.add('hidden');
            }
        });

    }

    detailStatusFormSubmit() {
        //Hide the submit button and show loading spinner
        this.detailStatusSubmitButtonTarget.classList.add('hidden');
        this.editOrderLoadingSpinnerTarget.classList.remove('hidden');
    }

    vendorChange(event) {
        if(event.target.value === '') {
            this.emrFormWrapperTarget.classList.add('hidden');
            this.hideMap();
            return;
        }
        this.vendorLoadingTarget.classList.remove('invisible');
        const form = event.target.closest('form');
        form.requestSubmit();
    }

    vendorDetailsRender(event) {
        if (event.target.id === 'vendor-details') {
            this.displayMap();
            this.displayForm();
            this.addVendorLocationIdToForm();
            this.vendorLoadingTarget.classList.add('invisible');
        }
    }

    displayMap() {
        const vendorCoordinates = document.querySelector('#vendor-coordinates');
        if (!vendorCoordinates) {
            return;
        }
        const latitude = vendorCoordinates.dataset.latitude;
        const longitude = vendorCoordinates.dataset.longitude;
        const mapElement = this.vendorMapTarget;
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });

        if (!latitude || !longitude) {
            this.vendorMapTarget.innerHTML = 'No location data available';
            return;
        }
        const map = new google.maps.Map(mapElement, {
            zoom: 15,
            center: { lat: parseFloat(latitude), lng: parseFloat(longitude) }
        });
    }

    hideMap() {
        this.vendorMapTarget.innerHTML = '';
    }

    displayForm() {
        this.emrFormWrapperTarget.classList.remove('hidden');
    }

    addVendorLocationIdToForm() {
        const vendorLocationId = document.querySelector('#vendor-coordinates').dataset.vendorLocationId;
        const existingInput = document.getElementById('purchase_order_vendor_location_id');
        if (existingInput) {
            existingInput.remove();
        }
        // Add hidden input to form
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'purchase_order[vendor_location_id]';
        input.value = vendorLocationId;
        input.id = 'purchase_order_vendor_location_id';
        input.autocomplete = 'off';
        this.emrFormTarget.appendChild(input);
    }

    updateTotal() {
        const total = this.calculateTotal();
        this.totalTarget.textContent = `$${total.toFixed(2)}`;
    }

    calculateTotal() {
        let total = 0;
        this.priceTargets.forEach((price, index) => {
            const wrapper = price.closest('.nested-form-wrapper');
            // If wrapper style has display: none, it is hidden and don't include in total
            if (wrapper.style.display === 'none') {
                return;
            }
            const quantity = parseFloat(this.quantityTargets[index]?.value || 1);
            total += parseFloat(price.value || 0) * quantity;
        });
        return total;
    }

    submitForm() {
        //Turn the text in the submit button to a loading spinner
        this.submitButtonTarget.classList.add('hidden');
        this.loadingTarget.classList.remove('hidden');
    }

    updateShippingOption(e) {
        const address = e.target.selectedOptions[0].dataset.address;
        const city = e.target.selectedOptions[0].dataset.city;
        const state = e.target.selectedOptions[0].dataset.state;
        const zip = e.target.selectedOptions[0].dataset.zip;
        document.querySelector('#purchase_order_shipping_address').value = address;
        document.querySelector('#purchase_order_shipping_city').value = city;
        document.querySelector('#purchase_order_shipping_state').value = state;
        document.querySelector('#purchase_order_shipping_zip').value = zip;
    }
}
