import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="springs-gear"
export default class extends Controller {
  updateShippingOption(e) {
    const address = e.target.selectedOptions[0].dataset.address;
    const city = e.target.selectedOptions[0].dataset.city;
    const state = e.target.selectedOptions[0].dataset.state;
    const zip = e.target.selectedOptions[0].dataset.zip;
    document.querySelector('#springs_gear_order_shipping_address').value = address;
    document.querySelector('#springs_gear_order_shipping_city').value = city;
    document.querySelector('#springs_gear_order_shipping_state').value = state;
    document.querySelector('#springs_gear_order_shipping_zip').value = zip;
    this.submitShippingForm(e);
  }

  submitShippingForm(event) {
    const loadingContainer = document.querySelector('#ship-form-loading');
    loadingContainer.classList.remove('hidden');
    event.target.closest('form').submit();
  }
}
