import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="student"
export default class extends Controller {
  connect() {
  }

  showInventoryTransferContainer() {
    const checkboxes = document.querySelectorAll('.inventory-checkbox-input')
    const transferContainer = document.querySelector('#inventory-transfer-container')
    const anyChecked = Array.from(checkboxes).some(input => input.checked)
    if (anyChecked) {
      transferContainer.classList.remove('hidden')
    } else {
      transferContainer.classList.add('hidden')
    }
  }
}
